// Generated by Framer (7aa0232)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["xYmAB3Glk", "dWV04LO4n"];

const variantClassNames = {dWV04LO4n: "framer-v-1uoeqwb", xYmAB3Glk: "framer-v-ldgpk8"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {Hamburger: "xYmAB3Glk", X: "dWV04LO4n"}

const getProps = ({color, height, id, tap, width, ...props}) => { return {...props, fZmpQGtle: color ?? props.fZmpQGtle ?? "var(--token-afc13746-dfe9-4cb3-8e99-c6882b9bc11b, rgb(11, 25, 45)) /* {\"name\":\"Midnight\"} */", Nyph_VZfg: tap ?? props.Nyph_VZfg, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "xYmAB3Glk"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;color?: string;tap?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, fZmpQGtle, Nyph_VZfg, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "xYmAB3Glk", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap1nuropt = activeVariantCallback(async (...args) => {
if (Nyph_VZfg) {
const res = await Nyph_VZfg(...args);
if (res === false) return false;
}
})

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-epP5b", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-ldgpk8", className)} data-framer-name={"Hamburger"} data-highlight layoutDependency={layoutDependency} layoutId={"xYmAB3Glk"} onTap={onTap1nuropt} ref={ref} style={{...style}} {...addPropertyOverrides({dWV04LO4n: {"data-framer-name": "X"}}, baseVariant, gestureVariant)}><motion.div className={"framer-1005eao"} data-framer-name={"1"} layoutDependency={layoutDependency} layoutId={"c9AEggE8S"} style={{backgroundColor: fZmpQGtle, rotate: 0}} variants={{dWV04LO4n: {rotate: 45}}}/><motion.div className={"framer-59xlrj"} data-framer-name={"3"} layoutDependency={layoutDependency} layoutId={"fTNkiaEIj"} style={{backgroundColor: fZmpQGtle, rotate: 0}} variants={{dWV04LO4n: {rotate: -45}}}/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-epP5b [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-epP5b .framer-1qg4djo { display: block; }", ".framer-epP5b .framer-ldgpk8 { cursor: pointer; height: 40px; overflow: visible; position: relative; width: 40px; }", ".framer-epP5b .framer-1005eao { flex: none; height: 2px; left: calc(50.00000000000002% - 24px / 2); overflow: visible; position: absolute; top: 14px; width: 24px; }", ".framer-epP5b .framer-59xlrj { bottom: 14px; flex: none; height: 2px; left: calc(50.00000000000002% - 24px / 2); overflow: visible; position: absolute; width: 24px; }", ".framer-epP5b.framer-v-1uoeqwb .framer-1005eao { top: calc(50.00000000000002% - 2px / 2); }", ".framer-epP5b.framer-v-1uoeqwb .framer-59xlrj { bottom: 19px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 40
 * @framerIntrinsicWidth 40
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"dWV04LO4n":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"fZmpQGtle":"color","Nyph_VZfg":"tap"}
 */
const FramerZmCwZUHyI: React.ComponentType<Props> = withCSS(Component, css, "framer-epP5b") as typeof Component;
export default FramerZmCwZUHyI;

FramerZmCwZUHyI.displayName = "icon / hamburger";

FramerZmCwZUHyI.defaultProps = {height: 40, width: 40};

addPropertyControls(FramerZmCwZUHyI, {variant: {options: ["xYmAB3Glk", "dWV04LO4n"], optionTitles: ["Hamburger", "X"], title: "Variant", type: ControlType.Enum}, fZmpQGtle: {defaultValue: "var(--token-afc13746-dfe9-4cb3-8e99-c6882b9bc11b, rgb(11, 25, 45)) /* {\"name\":\"Midnight\"} */", title: "Color", type: ControlType.Color}, Nyph_VZfg: {title: "Tap", type: ControlType.EventHandler}} as any)

addFonts(FramerZmCwZUHyI, [])